
jQuery(document).ready(function( $ ) {




    function equalizeSite() {
        if (!accessibleFont) {
            // Found an issue with this script when a browser is zooming in/out so we disable it on zoom.
            //		var zoom = detectZoom.zoom();
            //		if(zoom != 1) return;

            // Break Points: These are what you have setup in the _settings.scss
            // The actual points where it converts from mobile to tablet and tablet to desktop.
            // Sometimes there is not a tablet design in this case simply set it to the same as the desktop.
            var $bptablet = 768,
                $bpdesktop = 1024;

            // Designed Sizes: These sizes are referencing what you are using to design the site e.g. PSD, AI etc.
            // This is what you have your media queries set to. Remember you only need 1 set of styles for each device this
            // script will handle the rest.
            var $mobile = 730,
                $tablet = 1000,
                $desktop = 1200;

            // Initial Font Size: This is based on what the common copy font size is for each design.
            var $fsmobile = 16,
                $fstablet = 16,
                $fsdesktop = 16;

            var $ww = window.innerWidth;

            var $fontsize = 16;

            if($ww < $bptablet) {
                // mobile
                $fontsize = $fsmobile * ($ww/$mobile);
            } else if($ww < $bpdesktop) {
                // tablet
                $fontsize = $fstablet * ($ww/$tablet);
            } else if($ww <= 1350) {
                // desktop
                $fontsize = $fsdesktop * ($ww/$desktop);
            } else {
                $fontsize = 18;
            }

            var fontsize = Cookies.get('fontsize');

            if (typeof fontsize !== 'undefined') {
                $fontsize = parseInt($fontsize) + parseInt(fontsize);
            }

            $('html').css('font-size',$fontsize);


            defaultSize = $fontsize;
        }
    }

    equalizeSite();

    $(document).foundation();
    AOS.init({
        disable: 'mobile',
        once: true
    });


    if (Cookies.get('contrast') != 1) {
        if (Cookies.get('contrast') == 'high') {
            $('body').addClass('high-contrast');
        }
        if (Cookies.get('contrast') == 'low') {
            $('body').addClass('low-contrast');
        }
    }






    var defaultSize = 16,
        accessibleFont = false;




    $('a.accessibility').click(function (e) {
        e.preventDefault();
    });

    $('.smaller-font').click(function (e) {
        e.preventDefault();

        var fontsize = Cookies.get('fontsize');

        if (typeof fontsize === 'undefined') {
            fontsize = 0;
        }

        fontsize--;

        Cookies.set('fontsize', fontsize);
        equalizeSite();
    });

    $('.larger-font').click(function (e) {
        e.preventDefault();

        var fontsize = Cookies.get('fontsize');

        if (typeof fontsize === 'undefined') {
            fontsize = 0;
        }

        fontsize++;

        Cookies.set('fontsize', fontsize);
        equalizeSite();
    });

    $('.reset-font').click(function (e) {
        e.preventDefault();

        Cookies.set('fontsize', 0);
        equalizeSite();
    });


    $(window).resize(function () {
        equalizeSite();
    });



    equalizeSite();





    $('.high-contrast-btn').click(function (e) {
        e.preventDefault();
        $('body').removeClass('low-contrast');
        $('body').addClass('high-contrast');
        Cookies.set('contrast', 'high');
    });

    $('.low-contrast-btn').click(function (e) {
        e.preventDefault();
        $('body').removeClass('high-contrast');
        $('body').addClass('low-contrast');
        Cookies.set('contrast', 'low');

    });

    $('.reset-contrast-btn').click(function (e) {
        e.preventDefault();
        $('body').removeClass('low-contrast');
        $('body').removeClass('high-contrast');
        Cookies.set('contrast', 0);
    });

    if ( ! Modernizr.objectfit ) {
        $('.object-fit-wrapper').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');    if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }

    $('.menu-button').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('expanded');
    });

    $('button[data-accordion-id]').click(function () {
        var accordion_id = $(this).data('accordion-id');
        if (jQuery('#accordion' + accordion_id).hasClass('all-expanded')) {
            jQuery('#accordion' + accordion_id).parent('.page-accordion').find('.expand-all button').text('Expand All');
            jQuery('#accordion' + accordion_id).removeClass('all-expanded');
            jQuery('#accordion' + accordion_id).each(function () {
                var $acc = jQuery(this);
                var $openSections = $acc.find('.accordion-item .accordion-content');
                $openSections.each(function (i, section) {
                    $acc.foundation('up', $(section));
                });
            });
        } else {
            jQuery('#accordion' + accordion_id).addClass('all-expanded');
            jQuery('#accordion' + accordion_id).parent('.page-accordion').find('.expand-all button').text('Collapse All');
            jQuery('#accordion' + accordion_id).each(function () {
                var $acc = jQuery(this);
                var $openSections = $acc.find('.accordion-item .accordion-content');
                $openSections.each(function (i, section) {
                    $acc.foundation('down', $(section));
                });
            });
        }
    });




    (function (document, history, location) {
        var HISTORY_SUPPORT = !!(history && history.pushState);

        var anchorScrolls = {
            ANCHOR_REGEX: /#[^ 0]+/,
            OFFSET_HEIGHT_PX: 130,

            /**
             * Establish events, and fix initial scroll position if a hash is provided.
             */
            init: function () {
                this.scrollToCurrent();
                $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
                $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
            },

            /**
             * Return the offset amount to deduct from the normal scroll position.
             * Modify as appropriate to allow for dynamic calculations
             */
            getFixedOffset: function () {
				return this.OFFSET_HEIGHT_PX;
                //               return $('.header').outerHeight(true) + this.OFFSET_HEIGHT_PX;
            },

            /**
             * If the provided href is an anchor which resolves to an element on the
             * page, scroll to it.
             * @param  {String} href
             * @return {Boolean} - Was the href an anchor.
             */
            scrollIfAnchor: function (href, pushToHistory) {
                var match, anchorOffset;

                if (!this.ANCHOR_REGEX.test(href)) {
                    return false;
                }

                match = document.getElementById(href.slice(1));

                if (match) {
                    anchorOffset = $(match).offset().top - this.getFixedOffset();
                    $('html, body').animate({scrollTop: anchorOffset});

                    // Add the state to history as-per normal anchor links
                    if (HISTORY_SUPPORT && pushToHistory) {
                        history.pushState({}, document.title, location.pathname + href);
                    }
                }

                return !!match;
            },

            /**
             * Attempt to scroll to the current location's hash.
             */
            scrollToCurrent: function (e) {
                if (this.scrollIfAnchor(window.location.hash) && e) {
                    e.preventDefault();
                }
            },

            /**
             * If the click event's target was an anchor, fix the scroll position.
             */
            delegateAnchors: function (e) {
                var elem = e.target;

                if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
                    e.preventDefault();
                }
            }
        };

        $(document).ready($.proxy(anchorScrolls, 'init'));
    })(window.document, window.history, window.location);
});


